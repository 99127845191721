import Base from './base';

export default class Tables extends Base {
  list(query) {
    return this.apiClient.get('tables', query);
  }

  history(id) {
    return this.apiClient.get(`/tables/key-history/${id}`);
  }
}
