import config from '../config';
import ApiClient from './client';

import Auth from './auth';
import Users from './users';
import Tables from './tables';

const apiClient = new ApiClient(config.apiPrefix);

export default {
  auth: new Auth(apiClient),
  users: new Users(apiClient),
  tables: new Tables(apiClient),
  client: apiClient,
};
