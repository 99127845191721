import moment from 'moment';

export function formatTable(data) {
  const tableData = data.tableData;

  return tableData.map(row =>
    row.rowData.reduce(
      (acc, { key, value }) => {
        if (
          key === 'ModelTemplateCreateDateTime' ||
          key === 'ModelTemplateEditDateTime'
        ) {
          acc[key] = moment(value).format('DD/MM/YY HH:mm');
        } else {
          acc[key] = value || '-';
        }

        return acc;
      },
      { key: row.rowId },
    ),
  );
}

export function formatHistoryData({ transactionId, data }) {
  return data.reduce(
    (acc, { key, value }) => {
      if (
        key === 'ModelTemplateCreateDateTime' ||
        key === 'ModelTemplateEditDateTime'
      ) {
        acc[key] = moment(value).format('DD/MM/YY HH:mm');
      } else {
        acc[key] = value || '-';
      }

      return acc;
    },
    { key: transactionId },
  );
}

export function formatColumns(data) {
  return data.map(item => ({
    title: item.key.split('_').join(' '),
    dataIndex: item.key,
    key: item.key,
  }));
}
