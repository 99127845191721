const ROUTES = {
  HOME: '/',
  ADMIN: '/admin',
  ADMIN_USERS: '/admin/users',
  LOGIN: '/login',
  SIGNUP: '/signup',
  CONFIRM_EMAIL: '/confirmEmail/:hash',
  PROFILE: '/profile',
  RESET_PASSWORD: '/reset-password/:hash',
  PASSWORD_RECOVERY: '/password-recovery',
  HISTORY: '/history/:id',
};

export { ROUTES };
