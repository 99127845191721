import React from 'react';
import { Route, Switch } from 'react-router-dom';

import HomePage from './pages/HomePage';
import AdminUsersPage from './pages/AdminUsersPage';
import AdminContainer from './components/AdminContainer';
import MainLayout from './pages/MainLayout';
import ConfirmEmail from './pages/ConfirmEmail';
import AuthContainer from './components/AuthContainer';
import PrivateRoute from './components/PrivateRoute';
import ProfilePage from './pages/ProfilePage';
import HistoryPage from './pages/HistoryPage';

import { ROUTES } from './constants/routes';

const Routes = () => (
  <MainLayout>
    <Switch>
      <Route path={ROUTES.HOME} component={HomePage} exact />
      <Route path={ROUTES.HISTORY} component={HistoryPage} />
      <PrivateRoute path={ROUTES.ADMIN} component={AdminContainer} exact />
      <PrivateRoute
        path={ROUTES.ADMIN_USERS}
        component={AdminUsersPage}
        exact
      />
      <Route path={ROUTES.CONFIRM_EMAIL} component={ConfirmEmail} />
      <PrivateRoute path={ROUTES.PROFILE} component={ProfilePage} />
      <AuthContainer />
    </Switch>
  </MainLayout>
);

export default Routes;
