import React, { Component } from 'react';
import classes from './HistoryPage.module.scss';
import { Table, Spin } from 'antd';
import moment from 'moment';
import { formatHistoryData, formatColumns } from '../../helpers/tableHelpers';

export default class HistoryPage extends Component {
  componentDidMount() {
    const {
      match: { params },
      getHistory,
    } = this.props;
    getHistory(params.id);
  }

  render() {
    const { transactionHistory, loading } = this.props;
    if (loading)
      return (
        <div className={classes.spinContainer}>
          <Spin />
        </div>
      );

    return (
      <div className={classes.container}>
        {transactionHistory.length ? (
          <>
            <h2 className={classes.mainHeading}>History Page</h2>
            {transactionHistory.map(item => (
              <div>
                <div className={classes.heading}>
                  <span className={classes.time}>
                    {moment(item.timestamp.seconds.low * 1000).format(
                      'DD/MM/YY HH:mm',
                    )}
                  </span>
                  <span className={classes.transactionId}>
                    {item.transactionId}
                  </span>
                </div>
                <div className={classes.tablewrapper}>
                  <Table
                    key={item.transactionId}
                    columns={formatColumns(item.data)}
                    dataSource={[formatHistoryData(item)]}
                    pagination={false}
                  />
                </div>
              </div>
            ))}
          </>
        ) : (
          <h2 className={classes.mainHeading}>No history available</h2>
        )}
      </div>
    );
  }
}
