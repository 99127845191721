import Base from './base';

export default class Users extends Base {
  list(query) {
    return this.apiClient.get('users', query);
  }

  show(uid) {
    return this.apiClient.get(`users/${uid}`);
  }
}
