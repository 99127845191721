import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getList } from '../../redux/tables/actions';
import { list } from '../../redux/tables/selectors';

import HomePage from './HomePage';

const selector = createStructuredSelector({
  list,
});

const actions = { getList };

export { HomePage };

export default connect(selector, actions)(HomePage);
