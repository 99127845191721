export const prefix = 'tables';

export const TABLES_REQUEST = `${prefix}/TABLES_REQUEST`;
export const TABLES_SUCCESS = `${prefix}/TABLES_SUCCESS`;
export const TABLES_FAILURE = `${prefix}/TABLES_FAILURE`;

export const getList = () => ({
  types: [TABLES_REQUEST, TABLES_SUCCESS, TABLES_FAILURE],
  promise: api => api.tables.list(),
});

export const TABLE_HISTORY_REQUEST = `${prefix}/TABLE_HISTORY_REQUEST`;
export const TABLE_HISTORY_SUCCESS = `${prefix}/TABLE_HISTORY_SUCCESS`;
export const TABLE_HISTORY_FAILURE = `${prefix}/TABLE_HISTORY_FAILURE`;

export const getHistory = id => ({
  types: [TABLE_HISTORY_REQUEST, TABLE_HISTORY_SUCCESS, TABLE_HISTORY_FAILURE],
  promise: api => api.tables.history(id),
});
