import React, { Component } from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import { Layout, Menu, Button } from 'antd';
import { LINK_LABELS } from '../../constants/labels';
import classnames from './MainHeader.module.scss';
import logo from '../../assets/images/karuschain.png';

const { Header } = Layout;

export default class MainHeader extends Component {
  static propTypes = exact({
    pathname: PropTypes.string.isRequired,
    user: PropTypes.object,
    isHomePage: PropTypes.bool,
    logout: PropTypes.func.isRequired,
  });

  renderFreeMenu = () => [
    <Menu.Item key={ROUTES.LOGIN}>
      <Link to={ROUTES.LOGIN}>Login</Link>
    </Menu.Item>,
    <Menu.Item key={ROUTES.SIGNUP}>
      <Link to={ROUTES.SIGNUP}>Sign Up</Link>
    </Menu.Item>,
  ];

  renderLoggedMenu = role => [
    <Menu.Item key={ROUTES.HOME}>
      <Link to={ROUTES.HOME}>{LINK_LABELS.HOME}</Link>
    </Menu.Item>,
    <Menu.Item key={ROUTES.PROFILE}>
      <Link to={ROUTES.PROFILE}>{LINK_LABELS.PROFILE}</Link>
    </Menu.Item>,
    role === 'ADMIN' && (
      <Menu.Item key={ROUTES.ADMIN}>
        <Link to={ROUTES.ADMIN}>Admin Panel</Link>
      </Menu.Item>
    ),
    <Menu.Item key="logout">
      <Button
        icon="logout"
        type="danger"
        htmlType="button"
        onClick={this.props.logout}
      >
        logout
      </Button>
    </Menu.Item>,
  ];

  render() {
    return (
      <Header className={`${classnames.header}`}>
        <Link className={classnames.logo} to={ROUTES.HOME}>
          <img src={logo} alt="Karuschain" />
        </Link>
        {/* <Menu
          theme="dark"
          mode="horizontal"
          selectedKeys={[pathname]}
          className={classnames.menu}
        >
          {user ? this.renderLoggedMenu(user.role) : this.renderFreeMenu()}
        </Menu> */}
      </Header>
    );
  }
}
