import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { getHistory } from '../../redux/tables/actions';
import { history, historyLoading } from '../../redux/tables/selectors';

import HistoryPage from './HistoryPage';

const selector = createStructuredSelector({
  transactionHistory: history,
  loading: historyLoading,
});

const actions = { getHistory };

export { HistoryPage };

export default connect(selector, actions)(HistoryPage);
