import { combineReducers } from 'redux';
import * as actions from './actions';

export default combineReducers({
  loading(state = false, action) {
    switch (action.type) {
      case actions.TABLES_REQUEST:
        return true;
      case actions.TABLES_SUCCESS:
      case actions.TABLES_FAILURE:
        return false;
      default:
        return state;
    }
  },
  historyLoading(state = false, action) {
    switch (action.type) {
      case actions.TABLE_HISTORY_REQUEST:
        return true;
      case actions.TABLE_HISTORY_SUCCESS:
      case actions.TABLE_HISTORY_FAILURE:
        return false;
      default:
        return state;
    }
  },

  list(state = [], action) {
    switch (action.type) {
      case actions.TABLES_SUCCESS:
        return action.data.allTablesData;
      default:
        return state;
    }
  },
  history(state = [], action) {
    switch (action.type) {
      case actions.TABLE_HISTORY_SUCCESS:
        return action.data;
      default:
        return state;
    }
  },
});
