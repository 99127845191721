import React, { Component } from 'react';
import { Table, Spin } from 'antd';
import classnames from './HomePage.module.scss';
import { formatColumns, formatTable } from '../../helpers/tableHelpers';

export default class HomePage extends Component {
  componentDidMount() {
    const { getList } = this.props;
    getList();
  }

  render() {
    const { list, history } = this.props;
    if (!list.length)
      return (
        <div className={classnames.spinContainer}>
          <Spin />
        </div>
      );
    return (
      <div className={classnames.container}>
        <br />
        <br />
        <h2>Karuschain Demo</h2>
        {list.map(item => (
          <>
            <div className={classnames.tableHeading}>
              Table name: <span>{item.tableName}</span>
            </div>
            <Table
              pagination={false}
              size="small"
              dataSource={formatTable(item)}
              columns={formatColumns(
                item.tableData[0] && item.tableData[0].rowData,
              )}
              onRow={record => {
                return {
                  onClick: e => history.push(`history/${record.key}`),
                };
              }}
            />
          </>
        ))}
      </div>
    );
  }
}
